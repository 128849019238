var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useState } from 'react';
import noop from 'lodash/noop';
import { EventBusEvent } from '../enums/EventBus';
import eventBus from '../event-bus/EventBus';
import { LocaleProvider, useLocalization } from '../providers/LocaleProvider';
import { useKeyboardClose } from './keyboard';
var useGetToggleDisclaimer = function (toggleEvent) {
    if (toggleEvent === void 0) { toggleEvent = EventBusEvent.ToggleDisclaimer; }
    var _a = useLocalization(), locale = _a.locale, locales = _a.locales;
    return function (eventData) {
        eventBus.emit(toggleEvent, __assign(__assign({ open: true }, eventData), (eventData.content && {
            content: (React.createElement(LocaleProvider, { locale: locale, locales: locales[locale] }, eventData.content)),
        })));
    };
};
export var useOpenDisclaimer = function (eventData, eventOptions) {
    if (eventOptions === void 0) { eventOptions = {}; }
    var toggleDisclaimer = useGetToggleDisclaimer(eventData.toggleEvent);
    var _a = __read(useState(false), 2), isOpen = _a[0], setIsOpen = _a[1];
    // Starting point for https://home24.atlassian.net/browse/CHO-940
    // const { onClose } = useBackNavigation({
    //   isOpen,
    //   close: () => {
    //     eventBus.emit(eventData.toggleEvent, { open: false });
    //     setIsOpen(false);
    //     eventData.closeAction?.();
    //   },
    // });
    var closeAction = useCloseDrawer({
        toggleEvent: eventData.toggleEvent,
        callback: function () {
            var _a;
            setIsOpen(false);
            (_a = eventData.closeAction) === null || _a === void 0 ? void 0 : _a.call(eventData);
            // https://home24.atlassian.net/browse/CHO-940
            // onClose();
        },
        isOpen: isOpen,
    });
    useEffect(function () {
        if (isOpen && eventOptions.shouldUpdateContent) {
            toggleDisclaimer(__assign(__assign({}, eventData), { closeAction: closeAction }));
        }
    }, [isOpen, eventOptions.shouldUpdateContent, eventData.content]);
    return useCallback(function (event) {
        var preventDefault = eventOptions.preventDefault, stopPropagation = eventOptions.stopPropagation;
        if (preventDefault && event) {
            event.preventDefault();
        }
        if (stopPropagation && event) {
            event.stopPropagation();
        }
        setIsOpen(true);
        toggleDisclaimer(__assign(__assign({}, eventData), { closeAction: closeAction }));
    }, [eventData, setIsOpen]);
};
export var useCloseDrawer = function (options) {
    if (options === void 0) { options = {}; }
    var _a = options.toggleEvent, toggleEvent = _a === void 0 ? EventBusEvent.ToggleDisclaimer : _a, _b = options.callback, callback = _b === void 0 ? noop : _b, isOpen = options.isOpen;
    var closeDrawer = useCallback(function () {
        eventBus.emit(toggleEvent, { open: false });
        callback();
    }, [toggleEvent, callback]);
    useKeyboardClose(isOpen, closeDrawer);
    return closeDrawer;
};
export var useGetOpenDisclaimer = function () {
    var _a = __read(useState(null), 2), eventProps = _a[0], setEventProps = _a[1];
    var openDrawer = useOpenDisclaimer(__assign(__assign({}, eventProps), { closeAction: function () {
            var _a;
            setEventProps(null);
            (_a = eventProps === null || eventProps === void 0 ? void 0 : eventProps.closeAction) === null || _a === void 0 ? void 0 : _a.call(eventProps);
        } }));
    useEffect(function () {
        if (eventProps) {
            openDrawer();
        }
    }, [eventProps]);
    return setEventProps;
};
